<template>
  <v-container class="pt-6">
    <BaseForm
      :title="$route.meta.title"
      :loading="loading"
      :breadcrumbs="false"
      :elevation="2"
      @submit="handleSubmit"
    >
      <template v-slot:content>
        <v-row dense>
          <v-col cols="12">
            <v-alert
              elevation="2"
              border="right"
              colored-border
              type="error"
            >
              Al colocar el sitio en mantenimiento los usuarios no podrán acceder a las funcionalidades del mismo.
            </v-alert>
          </v-col>
          <v-col cols="12">
            <ValidationProvider
              name="Mantenimiento"
              rules="required"
              v-slot="{ errors }"
            >
              <v-switch
                v-model="maintenance.value"
                label="Mantenimiento"
                :error-messages="errors[0]"
              ></v-switch>
            </ValidationProvider>
          </v-col>
          <v-col v-if="maintenance.value" cols="12">
            <ValidationProvider
              name="Mensaje"
              rules="required"
              v-slot="{ errors }"
            >
              <v-text-field
                label="Mensaje de Mantenimiento"
                v-model="translation.translation"
                :error-messages="errors[0]"
              />
            </ValidationProvider>
          </v-col>
        </v-row>
      </template>
    </BaseForm>
  </v-container>
</template>

<script>
import BaseForm from '@/components/commons/BaseForm'
import SettingService from '@/services/setting'
import TranslationService from '@/services/translation'
export default {
  components: { BaseForm },
  data() {
    return {
      loading: false,
      maintenance: {},
      message: '',
      translation: {}
    }
  },
  async created() {
    await this.maintenanceSetting()
    await this.maintenanceTranslation()
  },
  methods: {
    async maintenanceSetting() {
      const { data } = await SettingService.fetch({
        'fields[settings]': 'id,key,value,type',
        'filter[key]': 'maintenance'
      })
      this.maintenance = data.data[0]
    },
    async maintenanceTranslation() {
      const { data } = await TranslationService.fetch({
        'fields[translations]': 'id,label,translation,category,language_id',
        'filter[category]': 'Mantenimiento',
        'filter[label]': 'maintenance_message',
        'filter[language_id]': 2,
      })
      this.translation = data.data[0]
    },
    async handleSubmit() {
      if (this.maintenance.value) {
        const res = await this.$dialog.warning({
          text: '¿ Está seguro que desea poner en mantenimiento el portal ?'
        })

        if (!res) return false
      }
      this.loading = true
      try {
        await SettingService.save(this.maintenance)
        if (this.maintenance.value) await TranslationService.save(this.translation)
        this.$dialog.notify.success('Estado de Mantenimiento modificado con éxito.')
      } catch (error) {
        console.log(error)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style></style>
